import React from "react"
import Layout from "../components/layout"
import "./providers.css"
import SEO from "../components/seo"



export default () => {
  return (
    <Layout>
      <SEO
        title={"Cashback·Medical - Frequently asked questions"}
        description={"Learn how our medical debt collection process works"}
      />
      <div className="mc-page container-fluid">
        <div className="row">
          <div style={{marginBottom:"35px"}} className="col-md-12">
            <div>
              <div className="accent-div"/>
              <h1>FAQs</h1>
              <p>Please see our frequently asked questions below.  If we missed something, give us a call at (407) 259-2969 and lets discuss your unique needs.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div style={{marginBottom:"75px"}} className="col-md-12">
            <Cards/>
          </div>
        </div>
      </div>
    </Layout>
  )
}


const Cards = () => {
  return(
    <div className="accordion" id="faqs">
      {
        questions.map(q =>  (
          <div className="card">
            <div className="card-header" id={q.id}>
              <h5 className="mb-0">
                <div data-toggle="collapse" data-target={`#${q.target}`}
                     aria-expanded="true" aria-controls={q.target}>
                  <h4>{q.title}</h4>
                </div>
              </h5>
            </div>
            <div id={q.target} className="collapse" aria-labelledby={q.id}
                 data-parent="#faqs">
              <div className="card-body">
                <div dangerouslySetInnerHTML={{__html: q.body}} />
              </div>
            </div>
          </div>))
      }
    </div>
  )



}


const questions = [
  {
    "id": "q1",
    "target": "t1",
    "title": "Why use Cashback Medical to collect patient debt?",
    "body": "<p>We augment your billing operations with a professional team dedicated to collecting your past due patient accounts so you can focus on serving your patients.</p>"
  },
  {
    "id": "q2",
    "target": "t2",
    "title": "How is Cashback Medical different from traditional debt collection?",
    "body": "<p>Our discount incentives and friendly representatives significantly increase the chances of successful patient payments while solidifying the positive relationship you have created with the patient.</p>"
  },
  {
    "id": "q3",
    "target": "t3",
    "title": "What if my patient debt is old?",
    "body": "<p>Cashback Medical will gladly accept any accounts. We can design an incentive structure based on the age of the patient account and ensure we operate within the designated statute of limitations for your state or jurisdiction.</p>"
  },
  // {
  //   "id": "q31",
  //   "target": "t31",
  //   "title": "How will providers know the status of an account?",
  //   "body": "<p>A provider representative can login to our client portal where you may access, add, delete, or change the incentive structure for any patient account.</p>"
  // },
  {
    "id": "q4",
    "target": "t4",
    "title": "How are patient payments processed?",
    "body": "<p>Cashback Medical uses <a href='https://stripe.com/'> Stripe</a> for payment processing.  You and your team will have access to the Stripe dashboard to manage payouts, bank accounts, and view details of patient payments made to your provider practice.</p>"
  },
  {
    "id": "q5",
    "target": "t5",
    "title": "How quickly will providers be paid?",
    "body": "<p>Provider's are paid monthly for all accounts that have adequately cleared our account since last monthly payment.</p>"
  },
  {
    "id": "q51",
    "target": "t51",
    "title": "How does the cashback or discount percentage work?",
    "body": "<p>Cashback Medical and the provider agree to a collection fee, and the patient's cashback or discount is shared from the collection fee.  No additional money will be charged to the provider beyond the collection fee.</p>"
  },
  {
    "id": "q52",
    "target": "t52",
    "title": "How do patients receive incentives to pay?",
    "body": "<p>Patient's receive a discount on the total amount due.</p>"
  },
]